import React from "react"
import IntegrationTemplate from "../../templates/integration"
import IntegrationStorageContent from 'components/content/integration-storage'
import { meta } from '../../configs/storage-providers';
const ContentPage = () => (
  <IntegrationTemplate
    key="storj"
    name="Storj"
    shortDescription="Store your server file and database backups to Storj.Secure backups on Storj like a breeze!"
    website={"https://www.storj.io/"}
    links={[{text: 'Documentation', url: 'https://docs.simplebackups.com/storage-providers/ghsg5GE15AMwMo1qFjUCXn/storj/cA5MPYmiSAaFcwuDvy4Mq3'}]}
    category="Storage"
    logo="storj-ico.png"
    metaTitle={meta.title.replace('##provider##', 'Storj')}
    metaDescription={meta.description.replace('##provider##', 'Storj')}
  >
    <IntegrationStorageContent
      keyRef="storj"
      service="Storj"
      provider="Storj"
      learnTags={['storj']}
      contentBlocks={[{
        title: 'Secure and Limited Access',
        text: 'When you link SimpleBackups with your Storj account, we ensure that access is strictly confined to the designated backup folder. This guarantees the security of your data and restricts access to only what\'s necessary.'
      },
        {
          title: 'Why Choose Storj with SimpleBackups?',
          text: '<ul>' +
            '<li><strong>✅ Enhanced Security:</strong> Storj\'s decentralized cloud storage offers superior security for your backups.</li>' +
            '<li><strong>✅ Scalability:</strong> Easily scale your backup storage needs with Storj\'s flexible infrastructure.</li>' +
            '<li><strong>✅ Cost-Effective:</strong> Benefit from Storj\'s competitive pricing models for long-term storage solutions.</li>'
        +'</ul>'
        },
      ]}
    />
  </IntegrationTemplate>
)

export default ContentPage
